<template>
  <div id="information">
    <div class="top"></div>
    <div class="news wrapper">
      <div class="title">
        <router-link to="/dash">首页<span v-html="'&nbsp'"></span></router-link>
        <router-link to="/help"
          >-<span v-html="'&nbsp'"></span>帮助<span v-html="'&nbsp'"></span
        ></router-link>
        <a href="javascript:void(0)"
          >-<span v-html="'&nbsp'"></span>房东利益如何最大化之合理装修</a
        >
      </div>
      <div class="content">
        <el-row>
          <el-col :span="18" class="left">
            <div class="news_title">
              <div class="newsText">房东利益如何最大化之合理装修</div>
            </div>
            <div class="news_content">
              <p>
                让自己的每一间房都为房东创造最大的收益，是每一位房东的愿望，可是往往事与愿违，总是感觉哪里出了问题，就是找不到根本所在，房东利器在这里为大家抛砖引玉，谈一下房东利益如何最大化之合理装修供大家参考。
              </p>
              <br />
              <p>
                先说一个例子，张玲大学毕业后在一家物流公司做经理助理，为了方便生活，家里拿钱，买了一套50平方米的二手房。后来，张玲结婚，想把这套50多平方米的房子出租，张玲一下子成了房东。
              </p>
              <br />
              <p>
                两个月过去了，张玲开始怀疑自己的眼光。后来，有人告诉她，这个房子要是简单装修一下，配些电器，肯定可以很快租出去。当然要把出租屋信息发布出去才可以。于是，张玲请人把墙面刮了大白，配了些简单家具和电器，还挂了钟表。不到半个月时间，就以1200元/月的价格租给了一对夫妻。
              </p>
              <br />
              <p>
                怎样才能让自己的房产以更高的价格租出去呢？单方面提高租金，市场不认可，到头来只会延长空置期，而使得收益减少。对于房东来说，如果不了解租赁市场，同样也会因为租金水平定位偏低而使得收益减少。
              </p>
              <br />
              <p>
                要想获得较为可观的出租收益，对房屋进行必要的装修是必不可少的，但装修的好坏和出租收益并不成正比，并非装修得越好就一定能够获得最好的收益。
              </p>
              <br />
              <p>
                而是应该在房屋出租之前事先对该区域出租人群进行一个准确的定位，并对其生活偏好、兴趣偏好进行一个简单的了解和分析，然后进行恰当地装修，可以通过软装饰来改善房子的居住品味，可以改变沙发、床罩、床饰、椅垫、窗帘、帷布、壁饰挂件等室内软体饰物，变得更富有居住品味，提高租金才会顺理成章，成功的几率也才会增大，就会达到租金收益与成本支出的最佳平衡点。
              </p>
              <br />
              <p>
                房东管家提醒广大房东，出租屋的管理不是单纯的计算水电费，收房租这么简单，如何使每间房屋的租金最大化，大部分租客满意，这才每一位房东的要考虑的事情。
              </p>
              <br />
              <p>
                后续出租屋管理软件—房东管家会推出房东利益如何最大化之合理控制空置期，敬请关注！
              </p>
            </div>
          </el-col>
          <el-col :span="5" class="right">
            <div class="recommendTitle">推荐</div>
            <div class="recommendList">
              <div class="recommendItem">
                <router-link to="/news1">
                  <img
                    src="../../assets/images/information/news1.jpeg"
                    alt=""
                  />
                  <div class="msg">
                    住房租赁市场回暖 监管强化助力住房租赁市场规范发展
                  </div>
                </router-link>
              </div>
              <div class="recommendItem">
                <router-link to="/news2">
                  <img
                    src="../../assets/images/information/news2.jpeg"
                    alt=""
                  />
                  <div class="msg">
                    六省市整治住房租赁市场 住房租赁立法亟须按下“快进键”
                  </div>
                </router-link>
              </div>
              <div class="recommendItem">
                <router-link to="/news3">
                  <img src="../../assets/images/information/new3.jpeg" alt="" />
                  <div class="msg">房屋租赁经纪行业制度</div>
                </router-link>
              </div>
              <div class="recommendItem">
                <router-link to="/news4">
                  <img src="../../assets/images/information/new4.jpeg" alt="" />
                  <div class="msg">中央加快住房租赁市场立法</div>
                </router-link>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
#information {
  .top {
    position: relative;
    top: 0;
    left: 0;
    height: 460px;
    width: 100%;
    background-image: url(../../assets/images/help/帮助背景.png);
    background-size: cover;
  }
  .news {
    .title {
      padding: 12px 0;
      margin-top: 20px;
      text-align: left;
      color: rgba(255, 255, 255, 0.7);
      border-bottom: 1px solid #d2d2d2;
      a {
        color: #5a5e66;
      }
    }
    .content {
      padding-bottom: 50px;
      .left {
        margin-top: 25px;
        .news_title {
          color: #000;
          overflow: hidden;
        }
        .newsText {
          height: 55px;
          line-height: 55px;
          text-align: center;
          font-size: 20px;
          background-color: #f1f3f6;
        }
        .news_content {
          text-align: left;
          color: #000;
          .bold {
            font-weight: 700;
            font-size: 18px;
          }
          p {
            font-size: 16px;
            font-weight: 300;
            color: rgb(51, 51, 51);
            text-align: justify;
            line-height: 24px;
          }
        }
      }
      .right {
        margin: 25px 0 0 50px;
        img {
          width: 200px;
          height: 115px;
        }
        background: #f2f6fa;
        min-height: 300px;
        .recommendTitle {
          color: #000;
          text-align: left;
          font-weight: bold;
          padding: 0 10px;
          margin: 20px 0;
          border-left: 3px solid #3b6798;
        }
        .recommendList {
          padding: 0 0 10px;
          .recommendItem {
            padding: 0 10%;
            margin-bottom: 15px;
            .msg {
              color: #717171;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
}
</style>
